// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/modals/confirm.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/modals/confirm.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

import { PLBaseModal, PLModalFooter } from "./base";
const defaultMessage = "Are you sure you want to delete this item?";
export const PLConfirmModal = ({
  onConfirm,
  open,
  setOpen,
  message = defaultMessage,
  size = "sm"
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return <PLBaseModal title="Please Confirm" open={open} setOpen={setOpen} size={size}>
      <>
        <div className="relative p-6 flex-auto rounded px-8 pt-6 pb-2 w-full">
          <p className="text-neutral-700 dark:text-neutral-300">{message}</p>
        </div>
        <PLModalFooter submitText="Yes" closeText="No" onSubmit={handleConfirm} onClose={handleClose} />
      </>
    </PLBaseModal>;
};
_c = PLConfirmModal;
var _c;
$RefreshReg$(_c, "PLConfirmModal");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;